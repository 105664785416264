<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="txtsearch"
              outlined
              dense
              label="Enter App Id"
              class="pt-1"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-btn medium color="primary" @click="btnsearch" class="pt-1 mt-1">
              <v-icon left>mdi-magnify</v-icon>SEARCH</v-btn
            >
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <!-- <v-row justify="center" no-gutters v-if="displaystatus == 'true'">
      <v-col cols="12" class="pa-2 mb-2" flat> -->
        <v-card class="mx-auto" outlined v-if="displaystatus == 'true'">
          <v-row class="pa-5">
            <v-col cols="12" md="8">
              <v-row dense>
                <v-col cols="4">App ID</v-col>
                <v-col cols="8">{{ appData.application_id }}</v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">Name</v-col>
                <v-col cols="8"
                  >{{ appData.name }} {{ appData.lastName }}</v-col
                >
                <v-col cols="12"><v-divider></v-divider></v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">Company</v-col>
                <v-col cols="8">{{ appData.mediaName }}</v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">Designation</v-col>
                <v-col cols="8">{{ appData.role_name }}</v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">Current City</v-col>
                <v-col cols="8">{{ appData.venue_name }}</v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
              </v-row>
              <v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="cbxcheck"
                      label="Do you want to transfer "
                      color="orange"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-row>
            </v-col>
            <v-col cols="12" md="4">
              <div class="pa-3 text-lg-center">
                <img
                  :src="this.$apiPath + appData.picHeadShot"
                  key="src"
                  class="imgId"
                />
              </div>
            </v-col>
          </v-row>

         
        </v-card>
      <!-- </v-col>
    </v-row> -->
    <v-row v-if="cbxcheck == true">
      <v-col cols="12" md="12">
         <v-card class="pa-2 mb-2 mt-2" flat>
      <v-card-actions>
        <v-row >
          <v-col cols="6">
            <v-select
                label="Select City"
                v-model="preferedCity"
                :items="MediaVenueForUpdate"
                item-text="venue_name"
                item-value="venue_id"
                dense
                outlined
                hide-details
                :item-disabled="[2]"

              ></v-select>
          </v-col>
          <v-col cols="6">
            <v-btn @click="save" color="primary"> Save </v-btn>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="1000"  top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
//import ViewApplication from "../../components/ViewApplication.vue";
export default {
  data() {
    return {
      cbxcheck: false,
      txtsearch: "",
      TDselected: [],
      desserts: [],
      MediaVenueForUpdate: [],
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      singleSelect: false,
      loadingstatus: false,
      overlay: false,
      displaystatus: "false",
      preferedCity: "",
      appData: {
        application_id: "",
        name: "",
        lastName: "",
        mediaName: "",

        role_name: "",
        picHeadShot: "",
        venue_name: "",
        mediaType_name: "",
        status_named: "",
        venue_id:"",

      },
      TransferData: {
        event_id: "",
        venue_id: "",
        application_id: "",
      },
    };
  },
  mounted() {
    sessionStorage.display_event_name = "IPL T20 2021";
    sessionStorage.display_venue_name = "Chinaswamy Stadium";

    this.$store.commit("setPageTitle", "Application Transfer");
    this.$store.commit("setPageTitleIcon", "mdi-file-swap-outline");
   // this.$store.commit("setEventName", sessionStorage.display_event_name);
  //  this.$store.commit("setVenueName", sessionStorage.display_venue_name);
    this.displaystatus = "false";
    this.bindmediaVenue(this.$store.state.eventInfo.EventId);
    //  this.bindReson("1");
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    btnsearch: function () {
     
      this.cbxcheck=false;
      this.Reload(this.txtsearch);
    },

    save: async function () {
      this.overlay = true;
      this.TransferData.event_id = this.$store.state.eventInfo.EventId;
      this.TransferData.venue_id = this.preferedCity;
      this.TransferData.application_id = this.txtsearch;
      if (this.preferedCity == "") {
        this.showSnackbar("error", "Select city !");
        this.overlay = false;
        return;
      }
      if(this.preferedCity==this.appData.venue_id){
         this.showSnackbar(
              "error",
              "Please select different city."
            );
             this.overlay = false;
            return;
      }
     await this.$axios
        .put("Application/applicationTransfer", this.TransferData)
        .then((res) => {
          if (res.data.status == "Success") {
            this.showSnackbar(
              "success",
              "Application No : " + this.txtsearch + " is transferd."
            );
            this.overlay = false;
            this.cbxcheck=false;
            this.displaystatus = "false";
          }
          // this.MediaVenueForUpdate = res.data.result;
        });
    },

    bindmediaVenue: async function (eventid) {
      await this.$axios
        .get(this.$apiPath + "Media/mediaVenue/" + eventid)
        .then((res) => {
          this.MediaVenueForUpdate = res.data.result;
        });
    },

    Reload: async function (app_id) {
      if (this.txtsearch == "") {
        this.showSnackbar("error", "Enter Application Id !");
        return;
      }
      this.loadingstatus = true;
      this.overlay = true;
      await this.$axios
        .get(
          "Application/forTransfer/" + this.$store.state.eventInfo.EventId + "/" + app_id
        )
        .then((res) => {
          this.appData.application_id = "";
          this.appData.application_id = res.data.result.app_id;
          this.appData.name = res.data.result.fName;
          this.appData.lastName = res.data.result.lastName;
          this.appData.mediaName = res.data.result.company_name;
          this.appData.role_name = res.data.result.jobtype_name;
          this.appData.picHeadShot = res.data.result.picHeadShot;
          this.appData.venue_name = res.data.result.preferedCityName;
          this.appData.venue_id = res.data.result.preferedCity;

          if (this.appData.application_id != null) {
            this.displaystatus = "true";
            this.overlay = false;
            this.loadingstatus = false;
          } else {
            this.displaystatus = "false";
            this.showSnackbar("error", "No Data Found !");
            this.overlay = false;
          }
        })
        .catch()
        .finally();
    },
  },
};
</script>

<style  scoped>
.tdalign {
  text-align: center;
}
.imgId {
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
  cursor: pointer;
  height: 200px;
}
</style>>

